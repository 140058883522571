<template>
  <div class="bankwith-box">
    <div class="wrp">
      <el-card class="box-card">
        <el-alert
          :title="$t('BankWithdrawal.TopTip')"
          type="warning"
          show-icon
        >
        </el-alert>
        <div class="forms-box" style="margin-bottom: 1rem">
            <div class="f-item">
                <div class="fund-t">{{ $t('Funds.Inwardrem.AccountName') }}</div>
                <div>{{ funds_data.account_name }}</div>
            </div>
            <div class="f-item">
                <div class="fund-t">{{ $t('Funds.Inwardrem.AccountNumber') }}</div>
                <div>{{ funds_data.account_number }}</div>
            </div>
            <div class="f-item">
                <div class="fund-t">{{ $t('Funds.Inwardrem.BankName') }}</div>
                <div>{{ funds_data.bank_name }}</div>
            </div>
        </div>
        <div class="forms-box">
            
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.AccountBalance') }}</div>
            <div class="huilv">${{ formatNum(bankForm.dAmount) }}</div>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.WithdrawAmount') }}</div>
            <CInput
              style="margin: 0"
              type="text"
              v-model="bankForm.wAmount"
              autocomplete="curent-password"
              :invalid-feedback="errorBackAmount"
              :is-valid="validatorAmount"
              valid-feedback=""
            >
            </CInput>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.WithdrawCurrency') }}</div>
            <div class="huilv">{{ bankForm.wCurrency }}</div>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.ServiceFee') }}</div>
            <div class="zfamt">{{ bankForm.wFee }}</div>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.TransferAmount') }}</div>
            <div class="zfamt">{{ getAmount }}</div>
          </div>
          <div style="text-align: center; margin-top: 2rem">
            <!-- <el-button
              @click="submitWithdrawal"
              type=""
              style="background: #00bea4; color: #fff; width: 100%"
              >{{ $t('BankWithdrawal.Submit') }}</el-button
            > -->
            <button @click="submitWithdrawal" class="btn custer sbtn" type="button">{{ $t('BankWithdrawal.Submit') }}</button>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {getHome,withdraw,getFundAccount,getAccountSetInfo} from "../../api/http"
export default {
  data() {
    return {
      errorBackAmount: "",
      flags: false,
      funds_data: "",
      fd_data: "",
      bankForm: {
        dAmount: 0,
        wAmount: null,
        wCurrency: "USD",
        wFee: "3%",
      },
    };
  },
  computed: {
      getAmount() {
          let percentVal = "97%"
          return (Number(this.bankForm.wAmount) * Number(this.toPoint(percentVal))).toFixed(2)
      }
  },
  created() {
      this.getDAmount()
      this.geFunds()
      this.getFd()
  },
  methods: {
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    // 提交提現
   submitWithdrawal() {
        if(this.bankForm.wAmount == null || this.flags) {
            return
        }
            this.reqWithdraw(this.bankForm.wAmount,"Wires",Number(this.fd_data))
            this.bankForm.wAmount = null
            this.flags = false
    },
    // 提現接口
    reqWithdraw(amt,pwy,act) {
        let data = {
            type: 'withdraw',
            uid: window.localStorage.getItem('uid'),
            amount: amt,
            pay_way: pwy,
            account: act
        }
        withdraw(data).then((res) => {
            if(res.code == 200) {
                this.$message.success(res.message)
                this.getDAmount()
                this.$router.push("/card-detail/account-flow")
            }else{
                this.$message.error(res.message)
            }
        })
    },
    // 獲取賬戶
    geFunds() {
        let obj = {
            type: "get_fund_account",
            uid: window.localStorage.getItem("uid"),
        }
        getFundAccount(obj).then((res) => {
            if(res.code == 200) {
                this.funds_data = res.data[0]
            }else {
                this.$message.error(res.message)
            }
        })
    },
    // 获取资金
    getFd() {
        let obj = {
            type: 'get_account_setting_info',
            uid: window.localStorage.getItem("uid"),
        }
        getAccountSetInfo(obj).then((res) => {
            console.log('getfd==>', res)
            if(res.code == 200) {
                this.fd_data = res.data.fund_account[0].account_number
            }else {
                this.$message.error(res.message)
            }
        })
    },
    // 百分比轉爲小數
    toPoint(percent) {
      var str = percent.replace("%", "");
      //   console.log('str==>', str)
      str = str / 100;
      return str;
    },
    // 小数转百分比显示
    toPercent(point) {
      if (point == 0) {
        return 0;
      }
      var str = Number(point * 100).toFixed(2);
      str += "%";
      return str;
    },
    // 獲取當前賬戶餘額
    getDAmount() {
        let obj = {
            type: "dashboard",
            uid: window.localStorage.getItem('uid')
        }
        getHome(obj).then((res) => {
            console.log('res=>', res)
            if(res.code == 200) {
                this.bankForm.dAmount = res.data.balance
            }else {
                this.$message.error(res.message)
            }
        })
    },
    validatorAmount(val) {
      console.log("val==>", val);
      let reg = /^(\+)?\d+(\.\d+)?$/;
      if (val === null) {
        this.errorBackAmount = "";
        this.flags = false;
        return;
      }
      if (reg.test(val)) {
        this.errorBackAmount = "";
        this.flags = false;
        return true;
      } else {
        this.errorBackAmount = this.$t('BankWithdrawal.InvalidAmount');
        this.flags = true;
        return false;
      }
    },
  },
};
</script>
<style scoped>
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 100%;
  outline:0;
}
/*  */
.bankwith-box {
  padding-left: 2.6rem;
}
.wrp {
  width: 65%;
  margin: 1rem auto;
}
.forms-box {
  width: 360px;
  margin: 0.5rem auto;
}
.forms-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.funds-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.fund-t{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.2rem;
}
.f-item{
    /* text-align: center; */
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: .5rem 0;
}
</style>